import smoothscroll = require('smoothscroll-polyfill');

smoothscroll.polyfill();

const headerHeightSmall = 60;
const headerHeightLarge = 80;

const bpLarge = 992;

let scrollAttempts = 0;

const scrollToTarget = (target: HTMLElement): void => {
  if (!target) {
    return;
  }

  const targetOffsetTop = (target.offsetTop || target.getBoundingClientRect().top)
    + window.pageYOffset;

  if (target) {
    window.scrollTo({
      left: 0,
      top: window.innerHeight < bpLarge
        ? targetOffsetTop - window.pageYOffset - headerHeightSmall - 15
        : targetOffsetTop - window.pageYOffset - headerHeightLarge - 15,
      behavior: 'smooth',
    });
  }
};

const scrollTo = (hash: string, withTimeout: boolean): void => {
  const scrollTarget = document.getElementById(hash.replace('+', ''));
  if (withTimeout && !scrollTarget && scrollAttempts < 10) {
    scrollAttempts += 1;
    setTimeout(() => {
      scrollTo(hash, withTimeout);
    }, 1000);
  } else {
    scrollToTarget(scrollTarget);
  }
};

const handleHash = (withTimeout: boolean): void => {
  const urlParts = window.location.href.split('#');
  const hash = urlParts[urlParts.length - 1];

  scrollTo(hash, withTimeout);
};

window.onhashchange = (): void => handleHash(false);

window.addEventListener('load', () => {
  if (window.location.href.indexOf('#') !== -1) {
    handleHash(true);
  }
});
